var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        class: _vm.up
          ? "greenV"
          : null + _vm.down
          ? "redV"
          : null + " player_data:number_value:val" + _vm.side
      },
      [
        _vm._v(" " + _vm._s(_vm.value) + " "),
        _c("div", { staticClass: "alert" }, [
          _vm.down
            ? _c("img", {
                attrs: {
                  src: require("@/assets/images/general/alerts/red.png")
                }
              })
            : _vm._e(),
          _vm.up
            ? _c("img", {
                attrs: {
                  src: require("@/assets/images/general/alerts/green.png")
                }
              })
            : _vm._e()
        ])
      ]
    ),
    _vm.name != ""
      ? _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }