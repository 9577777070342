<template>
  <div :class="'player_data_'+clas+side">
    <div :class="'player_data_'+clas+':number'" v-for="(value, index) in parameters" :key="index">
      <div :data-filter="side+value.name+'-dot'" class="player_data:number_value-dot">
        <img src="~@/assets/images/general/alerts/Oval-red.png" v-if="value.down" />
        <img src="~@/assets/images/general/alerts/Oval-green.png" v-if="value.up" />
      </div>
      <div :class="'player_data_'+clas+':number_value'" :data-filter="side+value.name">
        <SingleData :up="value.up" :down="value.down" :value="value.value" :side="side" />
      </div>
    </div>
  </div>
</template>

<script>
import Number from "@/components/general/card/Card.vue";
import SingleData from "@/components/general/single_data/SingleData.vue";
export default {
  data() {
    return {
      clas: this.$route.name
    };
  },
  components: {
    SingleData
  },
  props: {
    parameters: {
      type: Array,
      required: true
    },
    side: {
      type: String,
      required: true
    }
  },
  computed: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.player {
  &_data_field,
  &_data_fieldaway,
  &_data_fieldhome,
  &_data_table {
    display: flex;
    color: va(--color);
    margin-left: 20px;
    margin-right: 20px;
    align-items: center;
    flex-direction: row;
    &\:number {
      justify-content: center;
      display: flex;
      align-items: center;
      position: relative;
      &_value {
        position: relative;
      }
    }
  }

  &_data_tablehome,
  &_data_table {
    width: auto;
    justify-content: space-evenly;
    flex-direction: row;
    width: 62vw;
    border-top: 1px solid rgba(126, 126, 126, 0.3);
    border-bottom: 1px solid rgba(126, 126, 126, 0.3);
    padding: 12px 0;
    &\:number {
      display: inline-flex;
      width: 10vw;
      &_value {
      }
    }
  }
  &_data_field,
  &_data_fieldaway,
  &_data_fieldhome {
    &\:number {
      &_value {
        width: 41px;
        margin: 0 4px;
      }
    }
  }
  &_data_fieldaway {
    flex-direction: row-reverse;
    margin-right: 206px;
  }
}
</style>