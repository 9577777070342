<template>
  <div class="Card" ref="fullpanel">
    <div class="Card_box" ref="insidepanel">
      <img  v-if="appStyle == 'stats'"  
        @click="closePanel"
        class="setparameters_box:close"
        src="~@/assets/images/general/x_stats.svg"
        alt
      />
      <img  v-else  
        @click="closePanel"
        class="setparameters_box:close"
        src="~@/assets/images/general/x.svg"
        alt
      />
      <div class="Card_container">
        <div class="Card_container:left">
          <div class="Card_container:top">
            <div class="Card_container:left-side">
              <img v-if="appStyle != 'stats'" src="~@/assets/images/general/card/Player_white.png" alt />
              <div v-if="appStyle != 'stats'" class="Card_container:left-side_player">
                <img src="~@/assets/images/general/card/photoBW.png" alt />
              </div>
            </div>
            <div class="Card_container:left-side">
              <div class="Card_container:left-number">
                <div>{{ player.jerseyNum }}</div>
              </div>
              <div class="Card_container:left-title">{{ player.name }}</div>
            </div>
          </div>
          <div class="Card_container:bottom">
            <ul>
              <li
                v-for="(value, index) in player.parameters"
                :key="index"
                class="Card_data:number_value"
                @click="
                  refresh(player.jerseyNum, player.isOppositeTeam, value.name)
                "
              >
                <SingleData
                  :up="value.up"
                  :down="value.down"
                  :value="value.value"
                  :name="value.name"
                  :side="'home'"
                />
              </li>
            </ul>
          </div>
        </div>
        <div class="Card_container:right">
          <TemporalGraph
            v-if="graph"
            :key="count"
            :param="player.parameters"
            :paramdata="[
              {
                jerseyNum: this.jerseyNum,
                isOppositeTeam: this.isOppositeTeam,
              },
            ]"
            :parname="this.parameter"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TweenMax } from "gsap";
import SingleData from "@/components/general/single_data/SingleData.vue";
// import TemporalGraph from "@/components/general/graph/TemporalLine.vue";
export default {
  data() {
    return {
      newFilters: "",
      graph: false,
      jerseyNum: "",
      isOppositeTeam: "",
      parameter: "RHYTHM",
      count: 0
    };
  },
  components: {
    SingleData,
    TemporalGraph: () => import("@/components/general/graph/TemporalLine.vue"),
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
  },
  watch: {
    player(val) {
      this.jerseyNum = val.jerseyNum;
      this.isOppositeTeam = val.isOppositeTeam;
    },
    cardactive(val) {
      if (val) {
        this.openPanel();
        this.graph = true;
      } else {
        this.graph = false;
      }
    },
    graphUpd(val) {
      this.count = val;
    },
  },
  methods: {
    refresh(jerseyNum, isOppositeTeam, parameter) {
      this.graph = false;
      this.jerseyNum = jerseyNum;
      this.isOppositeTeam = isOppositeTeam;
      this.parameter = parameter;
      setTimeout(() => {
        this.graph = true;
      }, 100);
      // console.log(this.parameter);
    },
    openPanel() {
      const fullpanel = this.$refs.fullpanel;
      const insidepanel = this.$refs.insidepanel;
      TweenMax.to(fullpanel, { duration: 0.3, autoAlpha: 1 });
      TweenMax.to(insidepanel, {
        duration: 0.3,
        autoAlpha: 1,
        scale: 1,
        delay: 0.1,
      });
    },
    closePanel() {
      const data = {
        active: false,
        data: {},
      };
      this.$store.commit("card", data);
      const fullpanel = this.$refs.fullpanel;
      const insidepanel = this.$refs.insidepanel;
      TweenMax.to(fullpanel, { duration: 0.3, autoAlpha: 0, delay: 0.1 });
      TweenMax.to(insidepanel, {
        duration: 0.3,
        autoAlpha: 0,
        scale: 0.9,
      });
    },
  },
  computed: {
    cardactive() {
      return this.$store.state.field.card.active;
    },
    graphUpd() {
      return this.$store.state.faceto.updCnt;
    },
    appStyle() {
      return this.$store.state.appStyle;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.Card {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg-panel-color);
  visibility: hidden;
  text-align: center;
  z-index: 1000;
  &_box {
    color: var(--color);
    width: auto;
    display: inline-block;
    padding: 61px 47px;
    background-image: var(--background-image);
    box-shadow: 0px 0px 29px 10px rgba(0, 0, 0, 0.75);
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.9);
    position: absolute;
    &\:close {
      padding: 50px 150px;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
  &_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 1150px;
    &\:left,
    &\:right {
      &-side {
        position: relative;
        &:first-child {
          padding-right: 52px;
        }
        img {
          position: absolute;
          left: -60px;
          top: -70px;
        }
        &_player {
          img {
            top: 0;
            left: 0;
            position: relative;
            border: var(--border);
            // clip-path: url(#clipping);
            mask: url("~@/assets/images/general/card/mask.svg");
          }
        }
      }
      &-title {
        font-weight: bold;
        font-size: 18px;
      }
    }
    &\:left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 35%;
      &-side {
      }
      &-title {
        margin-left: -11px;
        padding-top: 20px;
      }
      &-number {
        font-size: 40px;
        font-weight: bold;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 17px;
          height: 40px;
          top: 0px;
          left: -10px;

          border: var(--player-border);
          border-right: 0px;
          background-color: $yellow;

          transform-origin: bottom left;
          -ms-transform: skew(-10deg, 0deg);
          -webkit-transform: skew(-10deg, 0deg);
          transform: skew(-10deg, 0deg);
        }
      }
    }
    &\:right {
      width: 60%;
    }
    &\:top {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
    }
    &\:bottom {
      padding-top: 30px;
      margin-left: -20px;
      ul {
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
}
</style>