<template>
  <div :class="'player' + side">
    <div class="player_containerhome" v-if="side == 'home'">
      <div
        :class="'player_detailshome' + player.color"
        :ref="'details' + side"
        @click="
          displayCard(
            true,
            player.jerseyNum,
            player.isOppositeTeam,
            player.parameters
          )
        "
      >
        <div class="player_detailshome:numberhome">{{ player.jerseyNum }}</div>
        <div class="player_detailshome:namehome" ref="name">
          {{ player.name }}
        </div>
      </div>
      <Data :parameters="player.parameters" :side="side" />
    </div>
    <div v-if="side == 'away'" class="player_containeraway">
      <Data :parameters="player.parameters" :side="side" />
      <div
        :class="'player_detailsaway' + player.color"
        :ref="'details' + side"
        @click="
          displayCard(
            true,
            player.jerseyNum,
            player.isOppositeTeam,
            player.parameters
          )
        "
      >
        <div class="player_detailsaway:nameaway" ref="name">
          {{ player.name }}
        </div>
        <div class="player_detailsaway:numberaway">{{ player.jerseyNum }}</div>
      </div>
    </div>
    <div v-else-if="side == ''" class="player_container">
      <div
        :class="'player_details' + player.color"
        ref="details"
        @click="
          displayCard(
            true,
            player.jerseyNum,
            player.isOppositeTeam,
            player.parameters
          )
        "
      >
        <div class="player_details:number">{{ player.jerseyNum }}</div>
        <div class="player_details:name" ref="name">{{ player.name }}</div>
      </div>
      <div class="player_battery" v-if="player.alarmsValues != undefined && player.alarmsValues[1] != undefined">
        <!--disabilitata la batteria rossa sotto il 20%-->
        <progress-bar :options="player.alarmsValues[1] <= 100 ? optionsBattery : optionsBatteryRed" :value="parseInt(100 - player.alarmsValues[1].toFixed(0))" />
      </div>
      <div class="player_battery" v-else/>
      <v-badge
        :content="player.cntCircle"
        :value="player.cntCircle"
        color="black"
        overlap
        offset-y="35"
        offset-x="5"
      >
      <div class="player_circle" v-if="player.alarmsValues != undefined && player.alarmsValues[1] != undefined">
      <progress-bar :options="optionsCircle" :value="parseInt(player.alarmsValues[0].toFixed(0))" />
      </div>
      <div class="player_circle" v-else/>
      </v-badge>
      <Data :parameters="player.parameters" :side="side" />
    </div>
  </div>
</template>

<script>
import Data from "@/components/general/data/Data.vue";
export default {
  data() {
    return {
      optionsBattery: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 12,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#2dbd2d",
          backgroundColor: '#455A64',
        },
        layout: {
          height: 25,
          width: 60,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: "battery",
        },
      },
      optionsBatteryRed: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 12,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#ff2858",
          backgroundColor: '#455A64',
        },
        layout: {
          height: 25,
          width: 60,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: "battery",
        },
      },
      optionsCircle: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 12,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#ffa500",
          backgroundColor: "#455A64",
        },
        layout: {
          height: 35,
          width: 35,
          verticalTextAlign: 27,
          horizontalTextAlign: 8,
          zeroOffset: 0,
          strokeWidth: 17,
          progressPadding: 0,
          type: "circle",
        },
      },
    };
  },

  components: {
    Data,
  },
  props: {
    player: {
      type: Object,
      require: true,
    },
    side: {
      type: String,
      required: true,
    },
  },
  watch: {},
  computed: {


  },
  methods: {
    displayCard(active, number, home, param) {
      const data = {
        active: true,
        data: {
          jerseyNum: number,
          isOppositeTeam: home,
        },
      };
      // Se per errore la Card risulta attiva la spengo prima di riaprirla
      if (this.$store.state.field.card.active) {
        const dataOff = {
          active: false,
        };
        this.$store.commit("card", dataOff);
      }
      this.$store.commit("card", data);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.playerhome,
.playeraway,
.player {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
  height: 34px;
  color: var(--color);
  margin: 15px 0;
  flex: 0 0 100%;
  &_container,
  &_containerhome,
  &_containeraway {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &_container,
  &_containerhome {
    justify-content: flex-start;
  }
  &_containeraway {
    justify-content: flex-end;
    width: 180px;
  }
  &_battery {
    justify-content: flex-end;
    width: 60px;
    margin-left: 10px;
    margin-top: 5px;
  }
  &_circle{
    justify-content: flex-end;
    height: 50px;
    width: 30px;
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 22px;
  }
  &_details,
  &_detailshome,
  &_detailsaway {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 180px;
    padding: 7px 15px;
    position: relative;
    border-left: 0;
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 10px;
      height: 100%;
      top: 0px;

      border: var(--player-border);
      background-color: $yellow;

      transform-origin: bottom left;
      -ms-transform: skew(-10deg, 0deg);
      -webkit-transform: skew(-10deg, 0deg);
      transform: skew(-10deg, 0deg);
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0px;

      border: var(--player-border);

      transform-origin: bottom left;
      -ms-transform: skew(-10deg, 0deg);
      -webkit-transform: skew(-10deg, 0deg);
      transform: skew(-10deg, 0deg);
    }
  }
  &_details_orange,
  &_detailshome_orange,
  &_detailsaway_orange {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 180px;
    padding: 7px 15px;
    position: relative;
    border-left: 0;
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 10px;
      height: 100%;
      top: 0px;

      border: var(--player-border);
      background-color: $orange;

      transform-origin: bottom left;
      -ms-transform: skew(-10deg, 0deg);
      -webkit-transform: skew(-10deg, 0deg);
      transform: skew(-10deg, 0deg);
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0px;

      border: var(--player-border);

      transform-origin: bottom left;
      -ms-transform: skew(-10deg, 0deg);
      -webkit-transform: skew(-10deg, 0deg);
      transform: skew(-10deg, 0deg);
    }
  }
  &_details_red,
  &_detailshome_red,
  &_detailsaway_red {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 180px;
    padding: 7px 15px;
    position: relative;
    border-left: 0;
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 10px;
      height: 100%;
      top: 0px;

      border: var(--player-border);
      background-color: $red;

      transform-origin: bottom left;
      -ms-transform: skew(-10deg, 0deg);
      -webkit-transform: skew(-10deg, 0deg);
      transform: skew(-10deg, 0deg);
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0px;

      border: var(--player-border);

      transform-origin: bottom left;
      -ms-transform: skew(-10deg, 0deg);
      -webkit-transform: skew(-10deg, 0deg);
      transform: skew(-10deg, 0deg);
    }
  }
  &_details_blue,
  &_detailshome_blue,
  &_detailsaway_blue {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 180px;
    padding: 7px 15px;
    position: relative;
    border-left: 0;
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 10px;
      height: 100%;
      top: 0px;

      border: var(--player-border);
      background-color: $blue;

      transform-origin: bottom left;
      -ms-transform: skew(-10deg, 0deg);
      -webkit-transform: skew(-10deg, 0deg);
      transform: skew(-10deg, 0deg);
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0px;

      border: var(--player-border);

      transform-origin: bottom left;
      -ms-transform: skew(-10deg, 0deg);
      -webkit-transform: skew(-10deg, 0deg);
      transform: skew(-10deg, 0deg);
    }
  }
  &_details,
  &_detailshome {
    margin-left: 15px;
    justify-content: flex-start;
    &:before {
      left: -10px;
      transform: skew(-10deg, 0deg);
    }
    &:after {
      right: 0px;
      border-left: 0px;
      transform: skew(-10deg, 0deg);
    }
    &\:number,
    &\:numberhome {
      padding: 0;
      padding-right: 10px;
      padding-left: 2px;
    }
    &\:namehome {
      width: 130px;
      position: absolute;
      left: 40px;
    }
  }
  &_detailsaway {
    margin-right: 15px;
    justify-content: flex-end;
    text-align: right;
    position: absolute;
    right: 0;

    &:before {
      right: -10px;
      transform: skew(10deg, 0deg);
    }
    &:after {
      left: 0px;
      transform: skew(10deg, 0deg);
      border-right: 0px;
    }
    &\:numberaway {
      padding: 0;
      padding-left: 10px;
      padding-right: 2px;
    }
    &\:nameaway {
      width: 130px;
      position: absolute;
      right: 40px;
    }
  }
  &_details_orange,
  &_detailshome_orange {
    margin-left: 15px;
    justify-content: flex-start;
    &:before {
      left: -10px;
      transform: skew(-10deg, 0deg);
    }
    &:after {
      right: 0px;
      border-left: 0px;
      transform: skew(-10deg, 0deg);
    }
    &\:number,
    &\:numberhome {
      padding: 0;
      padding-right: 10px;
      padding-left: 2px;
    }
    &\:namehome {
      width: 130px;
      position: absolute;
      left: 40px;
    }
  }
  &_detailsaway_orange {
    margin-right: 15px;
    justify-content: flex-end;
    text-align: right;
    position: absolute;
    right: 0;
    &:before {
      right: -10px;
      transform: skew(10deg, 0deg);
    }
    &:after {
      left: 0px;
      transform: skew(10deg, 0deg);
      border-right: 0px;
    }
    &\:numberaway {
      padding: 0;
      padding-left: 10px;
      padding-right: 2px;
    }
    &\:nameaway {
      width: 130px;
      position: absolute;
      right: 40px;
    }
  }
  &_details_blue,
  &_detailshome_blue {
    margin-left: 15px;
    justify-content: flex-start;
    &:before {
      left: -10px;
      transform: skew(-10deg, 0deg);
    }
    &:after {
      right: 0px;
      border-left: 0px;
      transform: skew(-10deg, 0deg);
    }
    &\:number,
    &\:numberhome {
      padding: 0;
      padding-right: 10px;
      padding-left: 2px;
    }
    &\:namehome {
      width: 130px;
      position: absolute;
      left: 40px;
    }
  }
  &_detailsaway_blue {
    margin-right: 15px;
    justify-content: flex-end;
    text-align: right;
    position: absolute;
    right: 0;
    &:before {
      right: -10px;
      transform: skew(10deg, 0deg);
    }
    &:after {
      left: 0px;
      transform: skew(10deg, 0deg);
      border-right: 0px;
    }
    &\:numberaway {
      padding: 0;
      padding-left: 10px;
      padding-right: 2px;
    }
    &\:nameaway {
      width: 130px;
      position: absolute;
      right: 40px;
    }
  }
  &_details_red,
  &_detailshome_red {
    margin-left: 15px;
    justify-content: flex-start;
    &:before {
      left: -10px;
      transform: skew(-10deg, 0deg);
    }
    &:after {
      right: 0px;
      border-left: 0px;
      transform: skew(-10deg, 0deg);
    }
    &\:number,
    &\:numberhome {
      padding: 0;
      padding-right: 10px;
      padding-left: 2px;
    }
    &\:namehome {
      width: 130px;
      position: absolute;
      left: 40px;
    }
  }
  &_detailsaway_red {
    margin-right: 15px;
    justify-content: flex-end;
    text-align: right;
    position: absolute;
    right: 0;
    &:before {
      right: -10px;
      transform: skew(10deg, 0deg);
    }
    &:after {
      left: 0px;
      transform: skew(10deg, 0deg);
      border-right: 0px;
    }
    &\:numberaway {
      padding: 0;
      padding-left: 10px;
      padding-right: 2px;
    }
    &\:nameaway {
      width: 130px;
      position: absolute;
      right: 40px;
    }
  }
}
.playerhome {
  // display: flex;
  // justify-content: flex-end;
}
.playeraway {
  display: flex;
  justify-content: flex-end;
}
</style>
