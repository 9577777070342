var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "player" + _vm.side }, [
    _vm.side == "home"
      ? _c(
          "div",
          { staticClass: "player_containerhome" },
          [
            _c(
              "div",
              {
                ref: "details" + _vm.side,
                class: "player_detailshome" + _vm.player.color,
                on: {
                  click: function($event) {
                    return _vm.displayCard(
                      true,
                      _vm.player.jerseyNum,
                      _vm.player.isOppositeTeam,
                      _vm.player.parameters
                    )
                  }
                }
              },
              [
                _c("div", { staticClass: "player_detailshome:numberhome" }, [
                  _vm._v(_vm._s(_vm.player.jerseyNum))
                ]),
                _c(
                  "div",
                  { ref: "name", staticClass: "player_detailshome:namehome" },
                  [_vm._v(" " + _vm._s(_vm.player.name) + " ")]
                )
              ]
            ),
            _c("Data", {
              attrs: { parameters: _vm.player.parameters, side: _vm.side }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.side == "away"
      ? _c(
          "div",
          { staticClass: "player_containeraway" },
          [
            _c("Data", {
              attrs: { parameters: _vm.player.parameters, side: _vm.side }
            }),
            _c(
              "div",
              {
                ref: "details" + _vm.side,
                class: "player_detailsaway" + _vm.player.color,
                on: {
                  click: function($event) {
                    return _vm.displayCard(
                      true,
                      _vm.player.jerseyNum,
                      _vm.player.isOppositeTeam,
                      _vm.player.parameters
                    )
                  }
                }
              },
              [
                _c(
                  "div",
                  { ref: "name", staticClass: "player_detailsaway:nameaway" },
                  [_vm._v(" " + _vm._s(_vm.player.name) + " ")]
                ),
                _c("div", { staticClass: "player_detailsaway:numberaway" }, [
                  _vm._v(_vm._s(_vm.player.jerseyNum))
                ])
              ]
            )
          ],
          1
        )
      : _vm.side == ""
      ? _c(
          "div",
          { staticClass: "player_container" },
          [
            _c(
              "div",
              {
                ref: "details",
                class: "player_details" + _vm.player.color,
                on: {
                  click: function($event) {
                    return _vm.displayCard(
                      true,
                      _vm.player.jerseyNum,
                      _vm.player.isOppositeTeam,
                      _vm.player.parameters
                    )
                  }
                }
              },
              [
                _c("div", { staticClass: "player_details:number" }, [
                  _vm._v(_vm._s(_vm.player.jerseyNum))
                ]),
                _c("div", { ref: "name", staticClass: "player_details:name" }, [
                  _vm._v(_vm._s(_vm.player.name))
                ])
              ]
            ),
            _vm.player.alarmsValues != undefined &&
            _vm.player.alarmsValues[1] != undefined
              ? _c(
                  "div",
                  { staticClass: "player_battery" },
                  [
                    _c("progress-bar", {
                      attrs: {
                        options:
                          _vm.player.alarmsValues[1] <= 100
                            ? _vm.optionsBattery
                            : _vm.optionsBatteryRed,
                        value: parseInt(
                          100 - _vm.player.alarmsValues[1].toFixed(0)
                        )
                      }
                    })
                  ],
                  1
                )
              : _c("div", { staticClass: "player_battery" }),
            _c(
              "v-badge",
              {
                attrs: {
                  content: _vm.player.cntCircle,
                  value: _vm.player.cntCircle,
                  color: "black",
                  overlap: "",
                  "offset-y": "35",
                  "offset-x": "5"
                }
              },
              [
                _vm.player.alarmsValues != undefined &&
                _vm.player.alarmsValues[1] != undefined
                  ? _c(
                      "div",
                      { staticClass: "player_circle" },
                      [
                        _c("progress-bar", {
                          attrs: {
                            options: _vm.optionsCircle,
                            value: parseInt(
                              _vm.player.alarmsValues[0].toFixed(0)
                            )
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", { staticClass: "player_circle" })
              ]
            ),
            _c("Data", {
              attrs: { parameters: _vm.player.parameters, side: _vm.side }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }