var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "player_data_" + _vm.clas + _vm.side },
    _vm._l(_vm.parameters, function(value, index) {
      return _c(
        "div",
        { key: index, class: "player_data_" + _vm.clas + ":number" },
        [
          _c(
            "div",
            {
              staticClass: "player_data:number_value-dot",
              attrs: { "data-filter": _vm.side + value.name + "-dot" }
            },
            [
              value.down
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/images/general/alerts/Oval-red.png")
                    }
                  })
                : _vm._e(),
              value.up
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/images/general/alerts/Oval-green.png")
                    }
                  })
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              class: "player_data_" + _vm.clas + ":number_value",
              attrs: { "data-filter": _vm.side + value.name }
            },
            [
              _c("SingleData", {
                attrs: {
                  up: value.up,
                  down: value.down,
                  value: value.value,
                  side: _vm.side
                }
              })
            ],
            1
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }