<template>
  <div>
    <div :class=" up ? 'greenV' : null + down ? 'redV' : null + ' player_data:number_value:val'+side">
      {{value}}
      <div class="alert">
        <img src="~@/assets/images/general/alerts/red.png" v-if="down" />
        <!-- <Red v-if="value.down" /> -->
        <img src="~@/assets/images/general/alerts/green.png" v-if="up" />
        <!-- <Green v-if="value.up" /> -->
      </div>
    </div>
    <div v-if="name != ''" class="name">{{name}}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: false,
      default: ""
    },
    up: {
      type: Boolean,
      required: true
    },
    down: {
      type: Boolean,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    side: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss">
.player,
.Card {
  &_data,
  &_data_table,
  &_data_field {
    &\:number {
      &:nth-child(1) {
        .player_data\:number_value-dot {
          img {
            top: 0px;
          }
        }
      }
      &:nth-child(2) {
        .player_data\:number_value-dot {
          img {
            top: 10px;
          }
        }
      }
      &:nth-child(3) {
        .player_data\:number_value-dot {
          img {
            top: 20px;
          }
        }
      }
      &_value {
        &-dot {
          display: none;
          position: absolute;
          height: 30px;
          img {
            width: 8px;
            height: 8px;
            position: absolute;
            opacity: 0;
            animation: blink 3s linear infinite;
          }
        }
        &\:valhome,
        &\:valaway,
        .greenV,
        .redV {
          font-size: 12px;
          text-align: center;
          padding: 0 5px;
          position: relative;
          .alert {
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
            left: 50%;
            top: 50%;
            svg,
            img {
              display: block;
              animation: rotating 2s linear infinite;
              width: 50px;
            }
          }
        }
        .green {
          color: $green;
        }
        .red {
          color: $red;
        }
        .name {
          margin-top: 20px;
          color: var(--color);
          font-size: 12px;
        }
      }
    }
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}
</style>